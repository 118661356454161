import { mapData } from "@/utilities/DataMapping";
import Utils from "@/utilities/Utils";

export interface IPurchaseLinesRequest {
    agreementNumber: number;
    dropAccountNumber: number;
    date: Date;
    categoryID: number;
    includeInactive: boolean;
    itemsPerPage: number;
    pageNumber: number
}

export class PurchaseLinesRequest implements IPurchaseLinesRequest {

    constructor(data?:IPurchaseLinesRequest) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data:IPurchaseLinesRequest) {
        mapData(data, { root: () => this });
    }

    agreementNumber: number = 0;
    dropAccountNumber: number = 0;
    date: Date = new Date(Utils.emptyDateValue);
    categoryID: number = 0;
    includeInactive: boolean = false;
    itemsPerPage: number = 0;
    pageNumber: number = 0;

}