
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Watch } from "vue-property-decorator";
    import ApiButton from "@/components/ApiButton.vue";
    import { IPurchaseLinesRequest, PurchaseLinesRequest } from "@/model/api/PurchaseLinesRequest";
    import ApiClient from "@/utilities/ApiClient";
    import Utils from "@/utilities/Utils";
    import ButtonToggler from "@/utilities/ButtonToggler";

    @Component({ components: { ApiButton } })
    export default class PurchasesDialogue extends Vue {

        //
        // -- properties
        //

        totalCount: number = -1;
        parameters = new PurchaseLinesRequest();

        purchaseLines: Array<any> = [];

        //
        // computed properties
        //

        get countText(): string {
            return this.totalCount === -1 ? "..." : this.totalCount.toString();
        }

        //
        // -- methods
        //

        @Watch("parameters.pageNumber")
        onPageChanged(): void {
            this.search();
        }

        async viewPurchaseLines(params: IPurchaseLinesRequest): Promise<void> {
            this.parameters.pageNumber = 0;
            this.purchaseLines.length = 0;
            this.parameters.update(params);
            this.parameters.pageNumber = 1;
            await this.search();
            this.$bvModal.show("purchasesDialogue");
        }

        async search(): Promise<void> {
            if (!Utils.isEmptyId(this.parameters.pageNumber)) {
                const response: { list: Array<any>, count: number } = await ApiClient.post("/api/report/purchases", this.parameters);
                this.totalCount = response.count;
                this.purchaseLines.length = 0;
                this.purchaseLines.push(...response.list);
            }
        }

        async download(event:Event): Promise<void> {
            const button = ButtonToggler.getButton(event);
            ButtonToggler.disableButton(button);

            const blob: Blob = await ApiClient.post("/api/report/downloadPurchases", this.parameters);
            Utils.downloadBlob(document, blob, "Purchases.xls");
            ButtonToggler.enableButton(button);
        }

        dateText():string {
            return Utils.monthYearText(this.parameters.date);
        }

        cancel(): void {
            this.$bvModal.hide("purchasesDialogue");
        }

    }

