export class ChartDataset {

    id: string = "";
    label: string = "";
    data: Array<number> = [];
    fill: boolean = false;
    backgroundColor: Array<string> = [];
    borderColor: Array<string> = [];
    borderWidth: number = 0;
    borderDash: Array<number> = [];

    spend: Array<number> = [];
    residents: Array<number> = [];
    daysInMonth: Array<number> = [];

}