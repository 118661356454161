import { ChartDataset } from "@/model/ChartDataset";
import { mapData } from "@/utilities/DataMapping";

export class ChartData<T> {

    update(data: any) {
        mapData(data, {
            root: () => this,
            datasets: () => new ChartDataset()
        });
    }

    labels: Array<string> = [];
    labelValues: Array<T> = [];
    datasets: Array<ChartDataset> = [];

}